/* eslint-disable react-hooks/exhaustive-deps */
import Header from 'components/Layouts';
import ShortlistDatatable from '../components/ShortlistDatatable';
import Container from 'components/Container/Container';

const Shortlist = () => {
  return (
    <div>
      <Header />
      <Container around>
        <ShortlistDatatable />
      </Container>
    </div>
  );
};

export default Shortlist;
