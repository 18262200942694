import { Button, styled } from '@mui/material';

import palette from 'theme/palette';

const StyledButton = styled(Button)(() => ({
  fontFamily: 'Roboto',
  fontSize: '13.5px',
  fontWeightL: 400,
  textTransform: 'none',
  backgroundColor: palette.primary,
  color: palette.main,
  padding: '13px 25px',
  minWidth: '100px',
  lineHeight: '1.1',
  borderRadius: '4px',
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: palette.primary,
    color: palette.main,
    opacity: '0.7',
  },
}));

export default StyledButton;
