import React from 'react';

import Container from 'components/Container/Container';
import HeaderRoot, { HeaderInner, HeaderLink } from './styled';

const Header = () => {
  return (
    <HeaderRoot>
      <Container>
        <HeaderInner>
          <HeaderLink to="/">Dashboard</HeaderLink>
          <HeaderLink to="/shortlist">Shortlist</HeaderLink>
        </HeaderInner>
      </Container>
    </HeaderRoot>
  );
};

export default Header;
