import React from 'react';

import { DateIcon, StyledOneDatePicker } from './styled';

const OneDatePicker = ({ value, onChange, placeholder }) => {
  return (
    <StyledOneDatePicker
      format="DD/MM/YYYY"
      value={value || null}
      onChange={onChange}
      componentsProps={{
        textField: {
          placeholder: placeholder,
          error: false,
        },
      }}
      slots={{
        openPickerIcon: DateIcon,
      }}
    />
  );
};

export default OneDatePicker;
