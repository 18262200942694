/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { SelectCheckbox } from 'components/Elements';
import { Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import { setArticleCheckbox } from '../api/shortlist';

const Checker = ({ Label, checked, onChange }) => {
  return (
    <Flexbox gap="10px" p="5px">
      <SelectCheckbox onChange={onChange} checked={checked} />
      <Paragraph content={Label} />
    </Flexbox>
  );
};

const ArticleCheckModule = ({ rowData }) => {
  const { isEdited, isSubEdited, isTranslationChecked, id } = rowData;

  const [checkers, setCheckers] = useState({
    EDITED: isEdited || false,
    SUB_EDITED: isSubEdited || false,
    TRANSLATION_CHECKED: isTranslationChecked || false,
  });

  const handleCheckChange = (type) => (event) => {
    const value = { [type]: event.target.checked };
    setCheckers({ ...checkers, ...value });

    const payload = {
      articleId: id,
      value: event.target.checked,
      checkBox: type,
    };
    setArticleCheckbox(payload);
  };

  useEffect(() => {
    if (rowData) {
      setCheckers({
        EDITED: isEdited,
        SUB_EDITED: isSubEdited,
        TRANSLATION_CHECKED: isTranslationChecked,
      });
    }
  }, [rowData]);

  return (
    <div>
      <Checker
        onChange={handleCheckChange('EDITED')}
        checked={checkers.EDITED}
        Label="Edited"
      />
      <Checker
        onChange={handleCheckChange('SUB_EDITED')}
        checked={checkers.SUB_EDITED}
        Label="Sub Edited"
      />
      <Checker
        onChange={handleCheckChange('TRANSLATION_CHECKED')}
        checked={checkers.TRANSLATION_CHECKED}
        Label="Translation Checked"
      />
    </div>
  );
};

export default ArticleCheckModule;
