import { Box, styled } from '@mui/material';
import { Button } from 'components/Elements';

import palette from 'theme/palette';

export const StyledModalBox = styled(Box)(({ theme }) => ({
  backgroundColor: palette.white,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  width: '100%',
  maxWidth: '700px',
  outline: 'none',
  borderRadius: '4px',
}));

export const StyledHeader = styled(Box)(() => ({}));

export const StyledCancelButton = ({ label, onClick }) => {
  return (
    <Button
      label={label}
      onClick={onClick}
      overrideStyles={{
        backgroundColor: palette.border,
        '&:hover': {
          backgroundColor: palette.border,
        },
      }}
    />
  );
};
