import { Box } from '@mui/material';
import React from 'react';

const Container = ({ children, around }) => {
  return (
    <Box
      sx={{ margin: '0 auto', padding: around ? '0px 60px 60px' : '0 60px' }}
    >
      {children}
    </Box>
  );
};

export default Container;
