import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import { Fragment, useState } from 'react';
import dayjs from 'dayjs';

import EdtiArticleModal from '../components/EdtiArticleModal';
import { Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import Popup from 'components/Popup/Popup';

import palette from 'theme/palette';

const columnArticleShape = [
  {
    minWidth: 150,
    Header: 'Section',
    accessor: 'mainTitle',
    Cell: ({ row }) => {
      const { mainTitle } = row.original;

      return (
        <Flexbox flexDirection="column" gap="10px">
          <Paragraph
            content={mainTitle}
            overrideStyles={{
              lineHeigth: '1.4',
              textAlign: 'left',
              fontSize: '13px',
            }}
            spacing
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Title',
    accessor: 'title',
    Cell: ({ row }) => {
      const { title } = row.original;
      const [open, setOpen] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      return (
        title && (
          <Fragment>
            <Flexbox flexDirection="column" gap="10px">
              <Box
                onClick={handleState}
                sx={{
                  cursor: 'pointer',
                  span: {
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText text={title || ''} length={50} />
              </Box>
            </Flexbox>

            <Popup open={open} onClose={handleState} header>
              <Paragraph
                content={title}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'left',
                }}
                spacing
              />
            </Popup>
          </Fragment>
        )
      );
    },
  },
  {
    minWidth: 200,
    Header: 'Outlet',
    accessor: 'outlet',
    Cell: ({ row }) => {
      const { outlet } = row.original;

      return (
        <Flexbox flexDirection="column" gap="10px">
          <Paragraph
            content={outlet}
            overrideStyles={{
              lineHeigth: '1.4',
              textAlign: 'left',
              fontSize: '13px',
            }}
            spacing
          />
        </Flexbox>
      );
    },
  },
  {
    minWidth: 200,
    Header: 'Summary',
    accessor: 'summary',
    Cell: ({ row }) => {
      const { summary } = row.original;
      const [open, setOpen] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      return (
        summary && (
          <Fragment>
            <Flexbox flexDirection="column" gap="10px">
              <Box
                onClick={handleState}
                sx={{
                  cursor: 'pointer',
                  span: {
                    fontWeight: 400,
                  },
                }}
              >
                <EllipsisText text={summary || ''} length={50} />
              </Box>
            </Flexbox>

            <Popup open={open} onClose={handleState} header>
              <Paragraph
                content={summary}
                overrideStyles={{
                  lineHeight: '1.4',
                  textAlign: 'left',
                }}
                spacing
              />
            </Popup>
          </Fragment>
        )
      );
    },
  },
  {
    minWidth: 150,
    Header: 'Publishing date',
    accessor: 'createdAt',
    Cell: ({ row }) => {
      const { createdAt } = row.original;
      return createdAt ? (
        <Paragraph content={dayjs(createdAt).format('DD/MM/YYYY h:mm:ss A')} />
      ) : (
        ''
      );
    },
  },
  {
    minWidth: 50,
    Header: 'Edit',
    accessor: 'edit',
    textAlign: 'center',
    Cell: ({ row }) => {
      const data = row.original;

      const [open, setOpen] = useState(false);

      const handleState = () => {
        setOpen((prevState) => !prevState);
      };

      return (
        <Fragment>
          <IconButton component="span" disableRipple onClick={handleState}>
            <EditIcon
              sx={{
                color: palette.border,
              }}
            />
          </IconButton>

          <EdtiArticleModal open={open} onClose={handleState} data={data} />
        </Fragment>
      );
    },
  },
];

export default columnArticleShape;
