import { Pagination, TableCell, TableRow, alpha, styled } from '@mui/material';

import palette from 'theme/palette';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontFamily: 'Roboto',
    lineHeight: '1.5',
    fontWeight: 500,
  },
  '& .MuiPaginationItem-page:hover': {
    color: '#fff',
    backgroundColor: palette.primary,
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: palette.primary,
  },
  '& .MuiPaginationItem-previousNext': {
    color: theme.palette.text.disabled,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: palette.main,
}));

export const StyledHeadCell = styled(TableCell)(() => ({
  fontFamily: 'Roboto',
  borderTop: '1px solid rgba(224, 224, 224, 1)',
  textAlign: 'left',
  fontWeight: 600,
  color: alpha(palette.text, 0.7),
  padding: '10px 15px',
}));

export const StyledBodyCell = styled(TableCell)(() => ({
  fontFamily: 'Roboto',
  textAlign: 'left',
  fontSize: '13px',
  fontWeight: 600,
  color: alpha(palette.text, 0.9),
  padding: '20px 15px',
  borderBottom: '1px solid rgba(12, 12, 12, 0.4)',
}));
