import { useState } from 'react';

import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import { Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import { BorderCard } from 'components/Elements';
import Popup from 'components/Popup/Popup';
import {
  createReport,
  getGoogleLink,
  reportGenerateName,
} from '../api/shortlist';
import toast from 'react-hot-toast';

const ExportShortlistModal = ({
  open,
  onClose,
  data,
  setUpdateAll,
  language,
}) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [fileType, setFileType] = useState(null);

  const handleSubmit = (type) => (_event) => {
    setFileLoader(true);
    setFileType(type);
    const idsLocale = JSON.parse(localStorage.getItem('shortlist_ids'));
    const payload = {
      ids: idsLocale ? idsLocale : [],
      format: type,
    };

    reportGenerateName({ format: type, language: language }).then(
      (response) => {
        const nameFile = response.data;

        const updatePayload = { ...payload, language };

        if (type === 'GOOGLE_DOC') {
          getGoogleLink(updatePayload).then((response) => {
            setFileLoader(false);
            const link = response.data;
            window.open(link, '_blank').focus();
            onClose();
            localStorage.setItem('shortlist_ids', JSON.stringify([]));
            setUpdateAll(true);
            setFileType(null);
          });
        } else {
          createReport(updatePayload)
            .then((response) => {
              setFileLoader(false);
              const blob = new Blob([response], {
                type: 'application/pdf',
                encoding: 'UTF-8',
              });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = nameFile;
              link.click();
              onClose();
              localStorage.setItem('shortlist_ids', JSON.stringify([]));
              setUpdateAll(true);
              setFileType(null);
            })
            .catch((error) => {
              setFileLoader(false);
              toast.error(error?.message);
            });
        }
      },
    );
  };

  return (
    <Popup open={open} onClose={onClose} title="Import Selected" header>
      <Flexbox flexDirection="column" alignItems="center" gap="20px">
        <Paragraph content="Import data as" bold />

        <Flexbox gap="20px" width="100%" justifyContent="center" pb="30px">
          {data?.map((item, index) => (
            <BorderCard key={index} onClick={handleSubmit(item)}>
              {fileLoader && fileType === item ? (
                <ScreenLoader fullHeight={false} fullScreen={false} />
              ) : (
                item
              )}
            </BorderCard>
          ))}
        </Flexbox>
      </Flexbox>
    </Popup>
  );
};

export default ExportShortlistModal;
