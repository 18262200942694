import { styled } from '@mui/material';
import React from 'react';

const StyledParagraph = styled('p')(({ spacing }) => ({
  fontFamily: 'Roboto',
  fontSize: '15px',
  letterSpacing: spacing ? '0.6px' : 'unset',
  lineHeight: '1.2',
  margin: '0',
}));

const Paragraph = ({
  content,
  bold = false,
  spacing = false,
  overrideStyles,
}) => {
  return (
    <StyledParagraph
      spacing={spacing ? 1 : 0}
      sx={{ ...overrideStyles, fontWeight: bold ? '500' : '400' }}
    >
      {content}
    </StyledParagraph>
  );
};

export default Paragraph;
