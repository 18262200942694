import React from 'react';
import StyledPaper from './styled';

const Paper = ({ children, space, overrideStyles }) => {
  return (
    <StyledPaper sx={overrideStyles} space={space ? 1 : 0}>
      {children}
    </StyledPaper>
  );
};

export default Paper;
