import React from 'react';

import StyledButton from './styled';

const Button = ({ label, overrideStyles, onClick }) => {
  return (
    <StyledButton sx={overrideStyles} onClick={onClick}>
      {label}
    </StyledButton>
  );
};

export default Button;
