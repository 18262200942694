import { useRoutes } from 'react-router-dom';
import React from 'react';

import { Dashboard } from 'features/dashboard';
import Shortlist from 'features/shortlist';

const Router = () => {
  const commonRoutes = [
    { path: '/', element: <Dashboard /> },
    { path: '/shortlist', element: <Shortlist /> },
  ];

  const routes = useRoutes(commonRoutes);

  return routes;
};

export default Router;
