/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import OneDatePicker from 'components/Elements/DatePicker/OneDatePicker';
import { Button, ClearButton, SearchField } from 'components/Elements';
import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import { H2, Paragraph } from 'components/Typography';
import Flexbox from 'components/Flexbox/Flexbox';
import { Datatable } from 'components/Table';
import Paper from 'components/Paper/Paper';

import columnArticleShape from '../core/columnShape';
import palette from 'theme/palette';

import { getArticle, moveToShortlist, removeAllArticles } from '../api/article';
import useDashboardDispatcher from '../redux/useDashboardDispatcher';

const ArticleDatatable = () => {
  const { updateDashboard } = useDashboardDispatcher();
  const [articles, setArticles] = useState(null);
  const [updateFetch, setUpdateFetch] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [lastHoursActive, setLastHoursActive] = useState(false);

  const [dates, setDates] = useState({
    dateFrom: '',
    dateTo: '',
  });

  const hasFilter =
    globalFilter || dates.dateFrom || dates.dateTo || lastHoursActive;

  const getArticleFetcher = (page = 0) => {
    let dateFromUTC = dates.dateFrom
      ? dayjs(dates.dateFrom).format('YYYY-MM-DDTHH:mm:ss')
      : '';

    let dateToUTC = dates.dateTo
      ? dayjs(dates.dateTo).format('YYYY-MM-DDTHH:mm:ss')
      : '';

    if (dateFromUTC && dateToUTC)
      if (dayjs(dateFromUTC).isAfter(dateToUTC)) {
        toast.error('Incorrect date range!');
        return;
      }

    if (lastHoursActive) {
      dateToUTC = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      dateFromUTC = dayjs().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss');
    }

    getArticle({
      article: globalFilter || null,
      dateFrom: dateFromUTC || null,
      dateTo: dateToUTC || null,
      page: page,
      size: 10,
      lastDayFilter: lastHoursActive || null,
    }).then((response) => {
      setArticles(response);
      setUpdateFetch(false);
    });
  };

  const handleLastHoursClick = () => {
    setLastHoursActive((prevState) => !prevState);
    setDates({
      dateFrom: '',
      dateTo: '',
    });
  };

  const handleStartDateChange = (newValue) => {
    setDates({ ...dates, dateFrom: newValue });
  };

  const handleEndDateChange = (newValue) => {
    setDates({ ...dates, dateTo: newValue });
  };

  const handlePaginate = (page) => {
    getArticleFetcher(page);
  };

  const handleMoveToShortlist = () => {
    const idsLocale = JSON.parse(localStorage.getItem('dashboard_ids'));
    const ids = idsLocale ? idsLocale : [];

    ids.forEach((id, index) => {
      moveToShortlist(id).then(() => {
        localStorage.setItem('dashboard_ids', JSON.stringify([]));
        localStorage.setItem('pages', JSON.stringify([]));
        if (index === ids.length - 1) {
          setUpdateFetch(true);
          setArticles(null);
        }
      });
    });
  };

  const handleRemoveAllArticles = () => {
    removeAllArticles().then(() => {
      setUpdateFetch(true);
    });
  };

  const handleFilterReset = () => {
    setGlobalFilter('');
    setLastHoursActive(false);
    setDates({
      dateFrom: '',
      dateTo: '',
    });
  };

  useEffect(() => {
    getArticleFetcher();
  }, [globalFilter, dates]);

  useEffect(() => {
    if (updateFetch) getArticleFetcher();
  }, [updateFetch]);

  useEffect(() => {
    if (updateDashboard) getArticleFetcher();
  }, [updateDashboard]);

  useEffect(() => {
    setArticles(null);
    localStorage.setItem('dashboard_ids', JSON.stringify([]));
  }, []);

  if (articles === null) return <ScreenLoader />;

  return (
    <Paper overrideStyles={{ paddingBottom: '10px' }}>
      <Flexbox p="30px" gap="20px" flexDirection="column">
        <Flexbox alignItems="center" gap="20px">
          <H2 content="Dashboard" />
          <Box marginLeft="auto">
            <Paragraph
              content={'Total : ' + articles.pageData.totalItems}
              bold
              spacing
            />
          </Box>
        </Flexbox>
      </Flexbox>

      <Datatable
        colorCell={false}
        data={articles.data}
        columnShape={columnArticleShape}
        filtering
        actionProps={
          <Fragment>
            <Button label="Move to Shortlist" onClick={handleMoveToShortlist} />
          </Fragment>
        }
        removeActionProps={
          <Fragment>
            {articles?.data?.length !== 0 && (
              <Button
                onClick={handleRemoveAllArticles}
                label="Remove All Articles"
                overrideStyles={{
                  minWidth: '180px',
                  backgroundColor: '#F5222D',
                  '&:hover': {
                    backgroundColor: '#F5222D',
                  },
                }}
              />
            )}
          </Fragment>
        }
        localeKey="dashboard_ids"
        filterProps={
          <Fragment>
            <SearchField
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Search article..."
              overrideStyles={{ width: '350px' }}
            />

            <OneDatePicker
              placeholder="Date from"
              value={dates.dateFrom}
              onChange={handleStartDateChange}
            />
            <OneDatePicker
              placeholder="Date end"
              value={dates.dateTo}
              onChange={handleEndDateChange}
            />

            <Button
              onClick={handleLastHoursClick}
              label="Last 24 h."
              overrideStyles={{
                minWidth: '120px',
                backgroundColor: lastHoursActive
                  ? palette.primary
                  : palette.active,
              }}
            />

            {hasFilter && (
              <ClearButton title="Clear Filters" onClick={handleFilterReset} />
            )}
          </Fragment>
        }
        paginateCount={articles.pageData.totalPages}
        currentPage={articles.pageData.page + 1 || 0}
        onPaginate={handlePaginate}
        _update={updateFetch}
      />
    </Paper>
  );
};

export default ArticleDatatable;
