import React, { forwardRef, useEffect, useRef } from 'react';
import { Checkbox } from '@mui/material';

import { BlankCheckBoxIcon, CheckBoxIcon } from './styled';

import palette from 'theme/palette';

const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      checkedIcon={
        <span style={{ color: palette.primary, width: '20px', height: '20px' }}>
          <CheckBoxIcon fontSize="small" />
        </span>
      }
      icon={
        <span style={{ color: palette.border, width: '20px', height: '20px' }}>
          <BlankCheckBoxIcon fontSize="small" />
        </span>
      }
      sx={{ padding: 0, borderRadius: 0 }}
    />
  );
});

export default SelectCheckBox;
