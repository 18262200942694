import { configureStore } from '@reduxjs/toolkit';

import DashboardSlice from 'features/dashboard/redux/DashboardSlice';

const reducers = {
  dashboardReducer: DashboardSlice,
};

export default configureStore({
  reducer: reducers,
  devTools: true,
});
