import SearchOffIcon from '@mui/icons-material/SearchOff';
import { IconButton, Tooltip } from '@mui/material';

const ClearButton = ({ title, onClick }) => {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} sx={{ color: '#e21148' }}>
        <SearchOffIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ClearButton;
