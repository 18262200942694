const palette = {
  main: '#F7F7FB',
  primary: '#2575fc',
  white: '#ffffff',
  active: '#B7B9C7',
  border: '#66759B',
  text: '#3F3737',
  mainBoxShadow:
    'rgba(145, 158, 171, 0.1) 0px 0px 2px 0px, rgba(145, 158, 171, 0.02) 0px 12px 24px -4px',
  activeBoxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
};

export default palette;
