/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import TextArea from 'components/Elements/TextArea/TextArea';
import Flexbox from 'components/Flexbox/Flexbox';
import Popup from 'components/Popup/Popup';

import { updateArticle } from '../api/article';

import useDashboardDispatcher from '../redux/useDashboardDispatcher';
import useForm from 'hooks/useForm';
import { Paragraph } from 'components/Typography';

const EdtiArticleModal = ({ open, onClose, data }) => {
  const { setDashboardUpdating } = useDashboardDispatcher();

  const { fields, onChangeForm, onResetForm, onInitForm } = useForm({
    id: data?.id || null,
    mainTitle: data?.mainTitle || null,
    title: data?.title || null,
    article: data?.article || null,
    shortArticle: data?.shortArticle || null,
    description: data?.description || null,
    tag: data?.tag || null,
    summary: data?.summary || null,
    audioText: data?.author || null,
    author: data?.author || null,
  });

  const handleUpdate = () => {
    updateArticle({
      ...fields,
    }).then(() => {
      setDashboardUpdating(true);
      onClose();
      onResetForm();
    });
  };

  const handleCloseModal = () => {
    onClose();
    onResetForm();
  };

  /* Effect for update field states for column data */
  useEffect(() => {
    if (data && open)
      onInitForm({
        id: data?.id || null,
        mainTitle: data?.mainTitle || null,
        title: data?.title || null,
        article: data?.article || null,
        shortArticle: data?.shortArticle || null,
        description: data?.description || null,
        tag: data?.tag || null,
        summary: data?.summary || null,
        audioText: data?.author || null,
        author: data?.author || null,
      });
  }, [data, open]);

  return (
    <Popup
      open={open}
      onClose={handleCloseModal}
      header
      title="Edit Information"
      submitLabel="Update"
      canceLabel="Cancel"
      onSubmit={handleUpdate}
      overrideStyles={{ maxWidth: '1000px' }}
    >
      <Flexbox gap="20px">
        <Flexbox flexDirection="column" width="100%" gap="20px">
          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Section"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Section"
                name="mainTitle"
                value={fields.mainTitle}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Title"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Title"
                name="title"
                value={fields.title}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Author"
                name="author"
                value={fields.author}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Author description"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Author description"
                name="description"
                value={fields.description}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Summary"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Summary"
                name="summary"
                value={fields.summary}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Short Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Short Article"
                name="shortArticle"
                value={fields.shortArticle}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Article"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Article"
                name="article"
                value={fields.article}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Audio Text"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Audio Text"
                name="audioText"
                value={fields.audioText}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>

          <Flexbox gap="20px" alignItems="start">
            <Flexbox flexDirection="column" width="100%" gap="10px">
              <Paragraph
                content="Tag"
                bold
                spacing
                overrideStyles={{ fontSize: '13px' }}
              />
              <TextArea
                placeholder="Tag"
                name="tag"
                value={fields.tag}
                onChange={onChangeForm}
                unicodeBidi={data?.id === 1406}
                overrideStyles={{
                  textAlign: data?.id === 1406 ? 'right' : 'left',
                }}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </Popup>
  );
};

export default EdtiArticleModal;
