import request from 'api/api.js';

export const getArticle = (payload) => {
  return request({
    url: '/article/all',
    method: 'POST',
    data: { ...payload },
  });
};

export const getReportAll = () => {
  return request({
    url: '/report/all',
    method: 'GET',
  });
};

export const updateArticle = (payload) => {
  return request({
    url: '/article/update',
    method: 'POST',
    data: { ...payload },
  });
};

export const createReport = (payload) => {
  return request({
    url: '/report/create-file',
    method: 'POST',
    data: { ...payload },
    responseType: 'blob',
  });
};

export const reportGenerateName = (payload) => {
  return request({
    url: '/report/generate-name',
    method: 'POST',
    data: { ...payload },
  });
};

export const moveToShortlist = (id) => {
  return request({
    url: '/shortlist/add/' + id,
    method: 'POST',
    data: {},
  });
};

export const removeAllArticles = () => {
  return request({
    url: '/article/remove/old',
    method: 'POST',
    data: {},
  });
};
