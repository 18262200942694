import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScrollBar from 'simplebar-react';
import React from 'react';

import { StyledCancelButton, StyledHeader, StyledModalBox } from './styled';
import Flexbox from 'components/Flexbox/Flexbox';
import { H2 } from 'components/Typography';
import { Button } from 'components/Elements';

const Popup = ({
  open,
  onClose,
  children,
  header,
  title,
  submitLabel,
  canceLabel,
  onSubmit,
  overrideStyles,
}) => {
  const renderHeader = (
    <StyledHeader>
      <Flexbox p="30px" alignItems="center">
        {title && <H2 content={title} />}
        <IconButton sx={{ marginLeft: 'auto' }} onClick={onClose}>
          <CloseIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Flexbox>
    </StyledHeader>
  );

  const renderBody = (
    <Box p="0 30px">
      <ScrollBar
        style={{
          maxHeight: '500px',
        }}
      >
        {children}
      </ScrollBar>
    </Box>
  );

  const renderAction = (
    <Box p="30px">
      <Flexbox gap="20px" justifyContent="end">
        {canceLabel && (
          <StyledCancelButton label={canceLabel} onClick={onClose} />
        )}
        {submitLabel && <Button label={submitLabel} onClick={onSubmit} />}
      </Flexbox>
    </Box>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBox sx={{ ...overrideStyles }}>
        {header && renderHeader}
        {renderBody}
        {renderAction}
      </StyledModalBox>
    </Modal>
  );
};

export default Popup;
