import { Box, styled } from '@mui/material';

import palette from 'theme/palette';

const StyledPaper = styled(Box)(({ space }) => ({
  backgroundColor: palette.white,
  boxShadow: palette.mainBoxShadow,
  borderRadius: '5px',
  padding: space ? '30px' : '0px',
}));

export default StyledPaper;
