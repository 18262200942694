import { Box, alpha, styled } from '@mui/material';

import palette from 'theme/palette';

export const StyledBorderCard = styled(Box)(() => ({
  padding: '40px',
  border: `1px dashed ${palette.active}`,
  cursor: 'pointer',
  transition: 'all .3s',
  fontSize: '20px',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: alpha(palette.border, 0.1),
  },
}));
