import { useState } from 'react';

const _isArray = (value) => {
  return Array.isArray(value);
};

const useForm = (initialState) => {
  const [fields, setValues] = useState(initialState);

  const _onChangeEditor = (event) => {
    console.log(event);
  };

  /* Change values handler */
  const _onChange = (event) => {
    const value = event.target.value;

    if (value) {
      setValues({
        ...fields,
        [event.target.name]: value,
      });
      return;
    }

    const divValue = event?.currentTarget?.textContent;
    const divName = event?.currentTarget?.id;
    if (divName) {
      const currentValue = fields[divName];

      if (divValue === currentValue) return;

      setValues({
        ...fields,
        [divName]: divValue,
      });

      return;
    }
  };

  /* Change values handler for multiselect */
  const _onChangeMultiselect = (event) => {
    const value = event.target.value;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setValues({ ...fields, [event.target.name]: newValue });
  };

  const _onCheckboxChange = (event) => {
    setValues({ ...fields, [event.target.name]: event.target.checked });
  };

  /* Setter to force a value change  */
  const _set = (key, value) => {
    const list = { ...fields };
    list[key] = value;
    setValues(list);
  };

  /* Force values initialization */
  const _init = (values) => {
    setValues({ ...values });
  };

  /* Resetting values state  */
  const _reset = () => {
    const emptyState = {};

    for (const key in fields) {
      if (_isArray(fields[key])) emptyState[key] = [];
      else emptyState[key] = null;
    }

    setValues(emptyState);
  };

  return {
    fields,
    onChangeForm: _onChange,
    onChangeMultiselect: _onChangeMultiselect,
    onResetForm: _reset,
    onInitForm: _init,
    onCheckbox: _onCheckboxChange,
    setValue: _set,
    onChangeEditor: _onChangeEditor,
  };
};

export default useForm;
